html {
  font-size: 24px; /* Imposta 1rem = 24px */
}
@media (max-width: 908px) {
  html {
    font-size: 18px; /* Imposta 1rem = 16px per i dispositivi mobili */
  }
}
/* Imposta la dimensione base di rem per dispositivi mobili (larghezza schermo inferiore a 768px) */
@media (pointer: coarse) {
  html {
    font-size: 16px; /* Imposta 1rem = 16px per dispositivi touch */
  }
}

input:focus,
input:hover {
  border-color: #6161617a; /* Colore del bordo invariato */
  background-color: transparent; /* Colore di sfondo invariato */
  outline: none; /* Rimuove la cornice di focus di default */
}
select:hover {
  border-color: #6161617a; /* Colore del bordo invariato */
  background-color: transparent; /* Colore di sfondo invariato */
  outline: none; /* Rimuove la cornice di focus di default */
}
select:focus {
  border-color: #6161617a; /* Colore del bordo invariato */
  background-color: transparent; /* Colore di sfondo invariato */
  outline: none; /* Rimuove la cornice di focus di default */
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232329;
}
.bg-angular-gradient {
    background: conic-gradient(
      from 90deg, 
      #020202 0%,
      #81502f 50%,
      #020202 100%
      
    );
    background-position:100% 0%; /* Sposta il centro del gradiente verso destra (60% orizzontale) */
    background-size: 150% 150%; 
  }

  .bg-angular-gradientmb {
    background: conic-gradient(
      from 90deg, 
      #111010 10%,
      #d3b287 50%,
      #111010 100%
      
    );
    background-position:100% 100%; /* Sposta il centro del gradiente verso destra (60% orizzontale) */
    background-size: 150% 160%; 
  }

  .bg-home {
    background: conic-gradient(
      from 80deg, 
      #000000 0%,
      #100E0B 18%,
      #52473b 54%,
      #100E0B 76%,
      #000000 100%
      
    );
    background-position:50% 30%; /* Sposta il centro del gradiente verso destra (60% orizzontale) */
    background-size: 150% 150%; 
  }

  .bg-home2 {
    background: conic-gradient(
      from 120deg, #050505 0, #3d3731 49%, #050505 75%
      
    );
    
    background-position: 52% 80%; /* Sposta il centro del gradiente verso destra (60% orizzontale) */
    background-size: 200% 120%; 
  }

  .bg-orders {
    background: conic-gradient(
      from 120deg, 
      #050505 0%,
   
      #46403a 49%,
    
      #050505 100%
      
    );
    
    background-position: 60% 80%; /* Sposta il centro del gradiente verso destra (60% orizzontale) */
    background-size: 200% 120%; 
  }

  .bg-orders2 {
    background: conic-gradient(
      from 270deg, 
      #1C1C1C 0%,
   
      #524940ee 49%,
    
      #1C1C1C 100%
      
    );
    
    background-position: 60% 80%; /* Sposta il centro del gradiente verso destra (60% orizzontale) */
    background-size: 200% 120%; 
  }


  .bg-orders3 {
    background: conic-gradient(
      from 120deg, #0a0a0a 0, #4e4841 49%, #0a0a0a 100%
      
    );
    
    background-position: 52% 40%; /* Sposta il centro del gradiente verso destra (60% orizzontale) */
    background-size: 200% 120%; 
   
  }

  .bg-credit {
 
      background: linear-gradient(
        90deg,
        #5de05da6 0%,
        #4053ffa6 100%
      );
     
   
  }
  

  .cardbg {
    background: linear-gradient(to right, rgba(156, 156, 156, 0.411),  rgba(95, 95, 95, 0.164));
  }
  
  .cardbg2 {
    background: linear-gradient(to right, rgba(0, 0, 0, 0),  rgba(114, 111, 108, 0));
  }
  .cardbg3 {
    background: linear-gradient(to right, rgba(0, 0, 0, 0.486),  rgba(0, 0, 0, 0.726));
  }

  /* Rimuovi ogni effetto hover */
  textarea:hover {
    border-color: #ffffff00; /* Mantieni il colore del bordo originale */
    cursor: text; /* Mantieni il cursore normale */
  }
  textarea:focus {
    border-color: inherit; /* Mantieni il colore del bordo */
    outline: none; /* Nessun bordo visibile */
    box-shadow: none; /* Evita effetti di ombra su focus */
    cursor: text; /* Mantieni il cursore normale */
  }



  .bg-credit2 {
 
    background: linear-gradient(
      90deg,
      #d4661ca6 0%,
      #991611a6 100%
    );
   
 
}
/* Fonts */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Imperial+Script&display=swap');

.font-inter {
  font-family: 'Inter', sans-serif;
}

.font-imperial {
  font-family: 'Imperial Script', cursive;
}

/* Personalizza la scrollbar */
.scrollbar-custom::-webkit-scrollbar {
  width: 8px; /* Larghezza della scrollbar */
}

.scrollbar-custom::-webkit-scrollbar-track {
  background-color: #2b2a2a00; /* Colore di sfondo della scrollbar */
  border-radius: 10px;
}

.scrollbar-custom::-webkit-scrollbar-thumb {
  background-color: #2c2c2c; /* Colore del thumb (la parte scorrevole) */
  border-radius: 10px;
}

.scrollbar-custom::-webkit-scrollbar-thumb:hover {
  background-color: #3a3a3a; /* Colore del thumb al passaggio del mouse */

}


.scrollbar-custom2::-webkit-scrollbar {
  width: 8px; /* Larghezza della scrollbar */
}

.scrollbar-custom2::-webkit-scrollbar-track {
  background-color: #18181800; /* Colore di sfondo della scrollbar */
  border-radius: 10px;
}

.scrollbar-custom2::-webkit-scrollbar-thumb {
  background-color: #c2c2c2; /* Colore del thumb (la parte scorrevole) */
  border-radius: 10px;
}

.scrollbar-custom2::-webkit-scrollbar-thumb:hover {
  background-color: #adadad; /* Colore del thumb al passaggio del mouse */

}


.shadow-custom {
  box-shadow: 0 -10px 100px rgb(12, 12, 12) /* Ombra sopra */
}

.shadow-custom2 {
  box-shadow: 10px -40px 100px rgb(0, 0, 0) /* Ombra sopra */
}
.shadow-button-custom {
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.144) /* Ombra sopra */
}
.shadow-button-custom2 {
  box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.274) /* Ombra sopra */
}
.image-container {
  position: relative;
  overflow: hidden;
}

.image-container img {
  transition: transform 0.5s ease, opacity 0.5s ease;
  transform: translateY(100px); /* Posiziona l'immagine fuori dalla vista all'inizio */
  opacity: 0; /* Inizia invisibile */
}

.image-container.image-change img {
  transform: translateY(0); /* L'immagine scende fino alla posizione finale */
  opacity: 1; /* L'immagine diventa visibile */
}

@media (min-width: 320px) {
  .grid-custom-cols-2 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 670px) {
  .grid-custom-cols-3 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1150px) {
  .grid-custom-cols-4 {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 1435px) {
  .grid-custom-cols-5 {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (min-width: 1735px) {
  .grid-custom-cols-6 {
    grid-template-columns: repeat(6, 1fr);
  }
  
}


.custom-dropdown {
  width: 6.7rem; /* Set width */
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  border-bottom: 2px solid #6161617a; /* Border styling */
  background-color: #f9f9f900; /* Background color */
  font-size: 0.6rem; /* Font size */
  cursor: pointer; /* Change cursor to pointer */
  color: white;
}
.custom-dropdown option {
  padding: 10px; /* Add padding to options */
  color: black; /* Text color */
}

.loading-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: #ffffff;
}

.loader {
  border: 4px solid #b2c2bf00; /* Colore di sfondo */
  border-top: 4px solid #ffffff; /* Colore dell'indicatore */
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  animation: spin 1s linear infinite; /* Animazione di rotazione */
  /* Spazio sotto il loader */
}
.loader3 {
  border: 3.5px solid #b2c2bf00; /* Colore di sfondo */
  border-top: 3.5px solid #ffffff; /* Colore dell'indicatore */
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  animation: spin 1s linear infinite; /* Animazione di rotazione */
  /* Spazio sotto il loader */
}
.loader2 {
  border: 7px solid #b2c2bf00; /* Colore di sfondo */
  border-top: 7px solid #ffffff; /* Colore dell'indicatore */
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  animation: spin 1s linear infinite; /* Animazione di rotazione */
  /* Spazio sotto il loader */
}

.custom-select option {
  background-color: rgb(68, 68, 68); /* Cambia lo sfondo */
  color: rgb(235, 235, 235); /* Colore del testo */
  border-radius: 1px;
}

.text-gradient {
  background: linear-gradient(to right, #FFFFFF, #999999); /* Verde -> Blu -> Viola */
  -webkit-background-clip: text; /* Clip del gradiente al testo */
  -webkit-text-fill-color: transparent; /* Rende il testo trasparente */

}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}



.customInput {
  position: relative !important;


}